import Rails from './modules/rails_ujs';
Rails.start();
require("packs/owner_phone_mask");
import "../stylesheets/application";
import "channels"
const images = require.context("../images", true);

import "core-js/stable";
import "regenerator-runtime/runtime";
import Feedbacks from "./modules/feedbacks";
import PhotoUploader from "./modules/photo_uploader";
import PhotoUploaderEvents from "./modules/photo_uploader_events";
import PhotoSorting from "./modules/photo_sorting";
import StatsChart from "./modules/stats_chart";
import DateSubmitter from "./modules/date_submitter";
import StartDatePicker from "./modules/start_date_picker";
import EndDatePicker from "./modules/end_date_picker";
import Comments from "./modules/comments";
import Menu from "./modules/menu";
import Qrs from "./modules/qrs";
import Nav from "./modules/nav";
import Payments from "./modules/payments";
import MobileNav from "./modules/mobile_menu";
import UserEdit from "./modules/user_edit";
import UserMessages from "./modules/user_messages";
import BusinessJobs from "./modules/business_jobs";
import Events from "./modules/events";
import ChatWidgets from "./modules/chat_widgets";
import "bootstrap/dist/js/bootstrap";
import "bootstrap-datepicker";
import "timeago/jquery.timeago";
import "./jquery.timeago.uk";
import "./translations.uk";
import "ekko-lightbox";
import "trix";
import "cocoon-js";
import $ from "jquery";
import "jquery-mask-plugin";

window.addEventListener("trix-file-accept", function(event) {
    event.preventDefault();
    alert("Завантаження файлів не підтримується!");
});

$(document).on("click", '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).ready(function() {
    $('.delete-btn-callback[data-method="delete"]').click(function(e) {
        if ($(this).hasClass("isDisabled")) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            $(this).addClass("isDisabled");
        }
    });

    $(".isDisabled").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
    });
});

new Menu("menu-icon", "header-nav", "header-nav--shown");
new Nav(
    [
        ".nav-businesses-content__item--current",
        ".nav-events-content__item--current",
        ".nav-businesses-header",
        ".nav-events-header",
    ],
    ".fas",
    "fa-chevron-up",
    "fa-chevron-down"
);
new MobileNav("menu_toggle", "nav", "nav--opened");

const controller = document.querySelector("body").dataset.controller;

if (controller === "businesses-gallery") {
    new PhotoUploader("#drag-drop-area");
    new PhotoSorting("#sortable");
}

if (controller === "businesses-show") {
    new StatsChart("#statsChart");
    // new StartDatePicker('#start-date');
    new EndDatePicker("#end-date");
    new DateSubmitter("a#submit-date");

    new Feedbacks("table#feedbacks-table");
    new Comments();
}

if (controller === "businesses-statistics") {
    new StatsChart("#statsChart");
    new StartDatePicker("#start-date");
    new EndDatePicker("#end-date");
    new DateSubmitter("a#submit-date");
}
if (controller === "feedbacks-index") {
    new Feedbacks("table#feedbacks-table");
}

if (controller === "comments-index") {
    new Comments();
}

if (controller === "user_messages-index") {
    new UserMessages();
}

if (controller === "business_feedback_qrs-index") {
    new Qrs();
}
if (controller === "payments-payment_api_data") {
    new Payments();
    new StartDatePicker("#start-date");
    new EndDatePicker("#end-date");
    new DateSubmitter("a#submit-date");
}
if (controller === "business_jobs-active" ||
    controller === "business_jobs-inactive" ||
    controller === "business_jobs-new" ||
    controller === "business_jobs-edit") {
    new BusinessJobs();
}
if (controller === "events-relevant" ||
  controller === "events-outdated" ||
  controller === "events-new" ||
  controller === "events-edit") {
    new Events();
}

if (controller === "events-new" ||
  controller === "events-edit") {
    new StartDatePicker("#start-date");
    new EndDatePicker("#end-date");
    new DateSubmitter("a#submit-date");
    new PhotoSorting("#sortable");
}

if (controller === "events-new") {
    new PhotoUploaderEvents("#drag-drop-area");
}

if (controller === "events-edit") {
    new PhotoUploader("#drag-drop-area");
}

if (controller === "chat_widgets-show") {
    new ChatWidgets();
}

document.addEventListener("DOMContentLoaded", scrollChat);

function scrollChat() {
    let scrollChat = document.querySelectorAll(".chat-container");
    let chatParent = document.querySelectorAll(".feedback-modal");
    let showChatItemDesktop = document.querySelector(
        ".desktop-only .showChat .chat-row__body-field"
    );
    let showChatItemMobile = document.querySelector(
        ".mobile-only .showChat .chat-row__body-field"
    );
    if (showChatItemDesktop) {
        showChatItemDesktop.scrollTop = showChatItemDesktop.scrollHeight;
    }
    if (showChatItemMobile) {
        showChatItemMobile.scrollTop = showChatItemMobile.scrollHeight;
    }
    for (let i = 0; i < scrollChat.length; i++) {
        chatParent[i].classList.add("showChat-js");
        scrollChat[i].scrollTop = scrollChat[i].scrollHeight;
        chatParent[i].classList.remove("showChat-js");
    }
}

// --- show chat ---
document.addEventListener("DOMContentLoaded", showChat);
if (document.getElementById('user_messages-index')) {
    const observer = new MutationObserver(function (mutations_list) {
        mutations_list.forEach(function (mutation) {
            mutation.addedNodes.forEach(function (added_node) {
                if (added_node) {
                    showChat()
                    if (mediaQuery &&
                        document.getElementById('chatHeaderMobile').dataset.mobile === 'collapsed') {
                        added_node.classList.add('hide')
                    }
                }
            });
        });
    });
    if (document.querySelector(".chat-row")) {
        observer.observe(document.querySelector(".chat-row"), { subtree: false, childList: true });
    }
}

const mediaQuery = window.matchMedia("(max-width: 993px)");

function showChat(e) {
    let chatField = document.querySelector(".chat-row__body-field");
    let chatHeaders = document.querySelectorAll(".chat-row__header-link");
    let chatHeadersDesktop = document.querySelectorAll(".chat-row__header-link");
    const chatHeaderMobile = document.getElementById('chatHeaderMobile')


    chatHeadersDesktop.forEach(item => {
        item.addEventListener('click', function handler() {
            chatHeadersDesktop.forEach(el => {
                el.classList.remove('active-header')
                if (mediaQuery.matches) {
                    el.classList.add('hide')
                }
            })
            item.classList.add('active-header')

            if (mediaQuery.matches) {
                chatHeaderMobile.style.display = 'flex'
                chatHeaderMobile.dataset.mobile = 'collapsed'
                chatHeaderMobile.querySelector('.username').textContent = item.querySelector('.username').textContent
                chatHeaderMobile.addEventListener('click', showList, false)
            } else {
                item.classList.remove('hide')
            }

            const newMsgClass = item.querySelector('.new-message')
            if (newMsgClass) {
                newMsgClass.classList.remove('new-message')
            }
            if (item === event.currentTarget) {
                item.classList.add('active-header')
            }
            document.querySelector('.chat-field-desktop').style.display = 'none'
        })
    })
}

function showList() {
    document.querySelector('.chat-row__body.showChat').classList.add('hide')
    document.getElementById('chatHeaderMobile').style = 'none'
    document.getElementById('chatHeaderMobile').dataset.mobile = ''
    let chatHeadersDesktop = document.querySelectorAll(".chat-row__header-link");
    chatHeadersDesktop.forEach(item => {
        item.classList.remove('hide', 'active-header')
    })
}
