import consumer from './consumer';

consumer.subscriptions.create(
    'ReadMessagesChannel', {
        connected() {
            // Called when the subscription is ready for use on the server
            console.log("ReadMessages connected...");
        },
        received(data) {
          console.log("Update received...")

          const currentBusinessId = location.pathname.split("/")[2]
          const activeHeaders = document.querySelectorAll('.chat-row__header-link')
          const receivedChatId = 'field' + data.chat_id

            activeHeaders.forEach(header => {
                if (header.id === receivedChatId) {
                    header.querySelector('.chat-row__header-info').classList.remove('new-message')
                }
            })

          // decrease the count value
          if (data.read_messages_count !== null && data.read_messages_count > 0) {
            const msgCount = document.querySelector('.top-nav a.chat-field span.user_messages-count')
            let newCount = parseInt(msgCount.textContent) - data.read_messages_count
            msgCount.textContent = newCount

            if (parseInt(msgCount.textContent) <= 0) {
              msgCount.style.display = 'none'
            }
          }

          // decrease the side menu count value
          let currentMenuItem = 'currentBusinessesCollapse_' + data.business_id
          const sideMenuItem = document.getElementById(currentMenuItem)
          const sideMenuCounter = sideMenuItem.querySelector('.feedback-counter-chat')
          if (sideMenuCounter ||
            document.getElementById('businesses-show') && data.update_for_count == currentBusinessId) {
            let newCountSide = parseInt(sideMenuCounter.textContent) - data.read_messages_count
            sideMenuCounter.textContent = newCountSide
            if (parseInt(sideMenuCounter.textContent) <= 0) {
              sideMenuCounter.style.display = 'none'
            }
            if(document.getElementById('newMsgCounter')) {
              document.getElementById('newMsgCounter').textContent = parseInt(document.getElementById('newMsgCounter').textContent) - data.read_messages_count
            }
          }

          const menuItem = document.getElementById(`menuItem_${data.business_id}`)
          const feedbackCounter = menuItem.querySelector('.feedback-counter');

          if (feedbackCounter) {
            let newFeedbackCount = parseInt(feedbackCounter.textContent) - data.read_messages_count;
            feedbackCounter.textContent = newFeedbackCount;

            if (newFeedbackCount <= 0) {
              feedbackCounter.style.display = 'none';
            }
          }

        }
    })
