export default class ChatWidgets {
  constructor() {
    console.log("Widget::constructor");
    this.init();
  }

  async init() {
    console.log("Widget::init")
    this.generateCodePreview()
    this.setupCopyButton()
    this.setupColorChangeListener()
    this.setupTemplateTypeChangeListener()
    this.setupInfoTextChangeListeners()
    this.setupPositionChangeListener()
    this.setupInitialColors()
  }

  debounce(func, delay) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), delay)
    }
  }

  setupInitialColors() {
    const widgetColorContainer = document.querySelector(".color-items-container")
    const backgroundColorContainer = document.querySelector(".color-items-container__bg")

    if (!widgetColorContainer || !backgroundColorContainer) {
      console.warn("No elements found for widget or background color.")
      return
    }

    const activeWidgetColorElement = widgetColorContainer.querySelector(".color-item__active")
    if (activeWidgetColorElement) {
      const widgetColors = ["#080F01", "#7CB342", "#0292E8", "#A648B7", "#F77600", "#F53214"]
      const selectedIndex = Array.from(widgetColorContainer.children).indexOf(activeWidgetColorElement)

      if (selectedIndex >= 0 && selectedIndex < widgetColors.length) {
        const selectedColor = widgetColors[selectedIndex]
        const backgroundColors = this.generateBackgroundColors(selectedColor)

        this.updateBackgroundColors(backgroundColorContainer, backgroundColors)

        const codePreview = document.getElementById("codePreview")
        if (!codePreview) {
          console.warn("The #codePreview element was not found")
          return
        }

        const chatWidgetData = codePreview.dataset.chatWidget
        try {
          const chatWidget = JSON.parse(chatWidgetData)
          const activeBackgroundColor = chatWidget.background_color
          this.setActiveBackgroundColor(backgroundColorContainer, activeBackgroundColor)
        } catch (error) {
          console.error("Data parsing error for #codePreview:", error)
        }
      }
    }
  }

  sendWidgetDataToServer(changes) {
    const endpoint = window.location.href
    const codePreview = document.getElementById("codePreview")
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    if (!codePreview) {
      console.warn("The #codePreview element was not found")
      return
    }

    const chatWidgetData = codePreview.dataset.chatWidget

    try {
      const chatWidget = JSON.parse(chatWidgetData)
      const dataToSend = {
        ...changes,
      }

      console.log(dataToSend);

      fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error sending data: ${response.statusText}`)
          }
          return response.json()
        })
        .then((result) => {
          console.log('Data sent successfully:', result)
        })
        .catch((error) => {
          console.warn('Error sending data:', error)
        });
    } catch (error) {
      console.error("Error parsing data for sending:", error)
    }
  }

  setupTemplateTypeChangeListener() {
    const templateSelect = document.getElementById("template_select")

    if (!templateSelect) {
      return
    }

    templateSelect.addEventListener("change", (event) => {
      const selectedIndex = (event.target.selectedIndex + 1).toString()
      this.updateCodePreview({ template_type: selectedIndex })
      this.sendWidgetDataToServer({ template_type: selectedIndex })
    })
  }

  setupColorChangeListener() {
    const widgetColorContainer = document.querySelector(".color-items-container")
    const backgroundColorContainer = document.querySelector(".color-items-container__bg")

    if (!widgetColorContainer || !backgroundColorContainer) {
      console.warn("No elements found for widget or background color.")
      return
    }

    const widgetColors = ["#080F01", "#7CB342", "#0292E8", "#A648B7", "#F77600", "#F53214"]

    widgetColorContainer.addEventListener("click", (event) => {
      const clickedElement = event.target

      if (clickedElement.classList.contains("color-item")) {
        const selectedIndex = Array.from(widgetColorContainer.children).indexOf(clickedElement)

        if (selectedIndex >= 0 && selectedIndex < widgetColors.length) {
          widgetColorContainer.querySelectorAll(".color-item").forEach((item) => {
            item.classList.remove("color-item__active")
          })
          clickedElement.classList.add("color-item__active")

          const selectedColor = widgetColors[selectedIndex]
          const backgroundColors = this.generateBackgroundColors(selectedColor)

          this.updateBackgroundColors(backgroundColorContainer, backgroundColors)

          const selectedBackgroundColor = backgroundColors[backgroundColors.length - 1]
          this.setActiveBackgroundColor(backgroundColorContainer, selectedBackgroundColor)

          this.updateCodePreview({
            widget_color: this.hexToRGB(selectedColor),
            background_color: selectedBackgroundColor,
          })

          this.sendWidgetDataToServer({
            widget_color: this.hexToRGB(selectedColor),
            background_color: selectedBackgroundColor,
          })
        }
      }
    })

    backgroundColorContainer.addEventListener("click", (event) => {
      const clickedElement = event.target

      if (clickedElement.classList.contains("color-item")) {
        backgroundColorContainer.querySelectorAll(".color-item").forEach((item) => {
          item.classList.remove("color-item__bg-active")
        })
        clickedElement.classList.add("color-item__bg-active")

        const selectedColor = clickedElement.dataset.color
        this.updateCodePreview({ background_color: selectedColor })
        this.sendWidgetDataToServer({ background_color: selectedColor })
      }
    })
  }

  setupInfoTextChangeListeners() {
    const textareas = [
      { id: "info_text_area", key: "info_text" },
      { id: "first_welcome_text_area", key: "first_welcome_text" },
      { id: "second_welcome_text_area", key: "second_welcome_text" },
      { id: "name_manager_text_area", key: "name_manager" },
    ]

    const debouncedUpdate = this.debounce((key, value) => {
      const updateData = {}
      updateData[key] = value
      this.updateCodePreview(updateData)
      this.sendWidgetDataToServer(updateData)
    }, 1000)

    textareas.forEach(({ id, key }) => {
      const textarea = document.getElementById(id)
      if (!textarea) {
        console.warn(`Textarea with id "${id}" not found.`)
        return
      }

      textarea.style.overflowY = "hidden"
      textarea.style.height = "auto"
      textarea.style.height = `${textarea.scrollHeight}px`

      textarea.addEventListener("input", (event) => {
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`

        const newValue = event.target.value
        debouncedUpdate(key, newValue)
      })
    })
  }

  setupPositionChangeListener() {
    const positionSelect = document.getElementById("position_select")

    if (!positionSelect) {
      return
    }

    positionSelect.addEventListener("change", (event) => {
      const selectedPosition = event.target.value.replace('_', '-')
      this.updateCodePreview({ position: selectedPosition })
      this.sendWidgetDataToServer({ position: selectedPosition })
    })
  }

  setActiveBackgroundColor(container, color) {
    const backgroundItems = container.querySelectorAll(".color-item")

    backgroundItems.forEach((item) => {
      if (item.dataset.color === color) {
        item.classList.add("color-item__bg-active")
      } else {
        item.classList.remove("color-item__bg-active")
      }
    })
  }

  generateBackgroundColors(baseColor) {
    return [
      "rgb(0, 0, 0)",
      this.modifyColorOpacity(baseColor, 0.8),
      this.modifyColorOpacity(baseColor, 0.3),
      this.modifyColorOpacity(baseColor, 0.1),
    ]
  }

  modifyColorOpacity(color, opacity) {
    const rgba = this.colorToRGBA(color, opacity)
    return rgba || color
  }

  colorToRGBA(color, opacity) {
    const hexMatch = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    if (hexMatch) {
      const r = parseInt(hexMatch[1], 16)
      const g = parseInt(hexMatch[2], 16)
      const b = parseInt(hexMatch[3], 16)
      return `rgba(${r}, ${g}, ${b}, ${opacity})`
    }
    return null
  }

  hexToRGB(hex) {
    const hexMatch = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (hexMatch) {
      const r = parseInt(hexMatch[1], 16)
      const g = parseInt(hexMatch[2], 16)
      const b = parseInt(hexMatch[3], 16)
      return `rgb(${r}, ${g}, ${b})`
    }
    return hex
  }

  updateBackgroundColors(container, colors) {
    const backgroundItems = container.querySelectorAll(".color-item")

    backgroundItems.forEach((item, index) => {
      if (index < colors.length) {
        item.style.backgroundColor = colors[index]
        item.dataset.color = colors[index]
      }
    })
  }

  generateCodePreview() {
    const codePreview = document.getElementById("codePreview");

    if (codePreview) {
      const chatWidgetData = codePreview.dataset.chatWidget

      try {
        const chatWidget = JSON.parse(chatWidgetData)

        const mappedChatWidget = {
          userIdBusiness: chatWidget.user_id_business,
          templatetype: chatWidget.template_type,
          widgetColor: chatWidget.widget_color,
          backgroundColor: chatWidget.background_color,
          nameManager: chatWidget.name_manager,
          firstWelkomeText: chatWidget.first_welcome_text,
          seconDwelcomeText: chatWidget.second_welcome_text,
          infoText: chatWidget.info_text,
          position: chatWidget.position || '',
          preview: true,
        };

        const codeGenerate = this.createScriptCode(mappedChatWidget)

        codePreview.textContent = codeGenerate
      } catch (error) {
        console.error("JSON parsing error:", error)
      }
    }
  }

  createScriptCode(mappedChatWidget) {
    return `
<!-- List In Ua Widget -->
<script>
    // global obj settings
    window.objWidget = ${JSON.stringify(mappedChatWidget, null, 6)};

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://listchat.web-heavensoft.com.ua/widget.js';
    document.head.appendChild(script);
</script>
<!-- End List In Ua Widget -->
    `;
  }

  updateCodePreview(changes) {
    const codePreview = document.getElementById("codePreview")

    if (codePreview) {
      const chatWidgetData = codePreview.dataset.chatWidget

      try {
        const chatWidget = JSON.parse(chatWidgetData)

        Object.assign(chatWidget, changes)

        const mappedChatWidget = {
          userIdBusiness: chatWidget.user_id_business,
          templatetype: chatWidget.template_type,
          widgetColor: chatWidget.widget_color,
          backgroundColor: chatWidget.background_color,
          nameManager: chatWidget.name_manager,
          firstWelkomeText: chatWidget.first_welcome_text,
          seconDwelcomeText: chatWidget.second_welcome_text,
          infoText: chatWidget.info_text,
          position: chatWidget.position || '',
          preview: true,
        }

        const codeGenerate = this.createScriptCode(mappedChatWidget)

        codePreview.textContent = codeGenerate
        codePreview.dataset.chatWidget = JSON.stringify(chatWidget)

        window.objWidget = mappedChatWidget

        if (typeof widgetReloadToggle === "function") {
          widgetReloadToggle()
        }
      } catch (error) {
        console.error("Code update error:", error)
      }
    }
  }

  setupCopyButton() {
    const codePreview = document.getElementById("codePreview")
    const copyButton = document.getElementById("codePreviewBtn")

    if (codePreview && copyButton) {
      copyButton.addEventListener("click", function () {
        const textToCopy = codePreview.textContent

        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            alert("Код успішно скопійовано!")
          })
          .catch((err) => {
            console.error("Помилка копіювання:", err)
          })
      })
    }
  }
}
